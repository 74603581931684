import React from "react";
import { Alert } from "@material-ui/lab";
import { Box, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

const BadgesValidatorErrorsAlert = (props) => {
  const intl = useIntl();

  if (!props.errors || !props.errors.length) {
    return "";
  }

  const errors = {
    ERROR_REVOKED: intl.formatMessage({
      id: 'badges-validator:error-revoked',
      defaultMessage: 'Badge has been revoked.'
    }),
    ERROR_PARSING: intl.formatMessage({ id: 'badges-validator:error-parsing', defaultMessage: 'Badge couldn\'t be parsed correctly.' }),
    ERROR_FATAL: intl.formatMessage({ id: 'badges-validator:error-fatal', defaultMessage: 'An unexpected error ocurred. Please try later.' }),
  };

  return (
    <Box mt={2}>
      <Alert variant="standard" color="error">
        {props.errors.map((error) => (
          <Box key={error.result}>
            <Typography>{errors[error.error_id] ?? error.result}</Typography>
            {error.revocation_list_id && (
              <Typography>
                <a href={error.revocation_list_id} target="_blank" rel="noreferrer">
                  <FormattedMessage id="badges-validator:open-revokation-list" defaultMessage="Open revocation list" />
                </a>
              </Typography>
            )}
            {error.revocation_reason && (
              <Typography>
                <FormattedMessage id="badges-validator:revocation-reason" defaultMessage="Revocation reason: {reason}" values={{ reason: error.revocation_reason }} />
              </Typography>
            )}
          </Box>
        ))}
      </Alert>
    </Box>
  );
};

export default BadgesValidatorErrorsAlert;
