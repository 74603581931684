import {
  Box,
  Button,
  Icon,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import BadgesValidatorBadgeClass from "./BadgesValidatorBadgeClass";
import BadgesValidatorEndorsements from "./BadgesValidatorEndorsements";
import BadgesValidatorEvidence from "./BadgesValidatorEvidence";
import BadgesValidatorIssuer from "./BadgesValidatorIssuer";
import BadgesValidatorRecipient from "./BadgesValidatorRecipient";

const useStyle = makeStyles((theme) => ({
  resultText: {
    fontSize: "2em",
    color: `${theme.palette.success.main}`,
  },
  resultIcon: {
    fontSize: "3em",
    marginRight: "0.1em",
    color: `${theme.palette.success.main}`,
  },
  resultTextError: {
    fontSize: "2em",
    color: `${theme.palette.error.main}`,
  },
  resultIconError: {
    fontSize: "3em",
    marginRight: "0.1em",
    color: `${theme.palette.error.main}`,
  },
}));

const BadgesValidatorResult = (props) => {
  const classes = useStyle();

  return (
    <Box>
      <Box mt={2} data-test="validator-result">
        {props.validationResult.valid ? (
          <Box display="flex" justifyContent="center">
            <Icon className={classes.resultIcon}>check_circle</Icon>
            <Typography className={classes.resultText} variant="body1">
              <FormattedMessage
                id="badges-validator:badge-satus-valid"
                defaultMessage="Valid"
                description="Badge status valid"
              />
            </Typography>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <Icon className={classes.resultIconError}>error</Icon>
            <Typography className={classes.resultTextError} variant="body1">
              <FormattedMessage
                id="badges-validator:badge-satus-invalid"
                defaultMessage="Invalid"
                description="Badge status invalid"
              />
            </Typography>
          </Box>
        )}
      </Box>

      {props.validationResult.json.badge && (
        <Box mt={2}>
          <BadgesValidatorBadgeClass badge={props.validationResult.json.badge} fallbackImage={props.fallbackImage} />
        </Box>
      )}

      {props.validationResult.json.recipient && (
        <Box mt={2}>
          <Typography variant="overline">
            <FormattedMessage
              id="badges-validator:recipient-subtitle"
              defaultMessage="Recipient"
              description="Recipient subtitle"
            />
          </Typography>
          <Paper>
            <BadgesValidatorRecipient
              recipient={props.validationResult.json.recipient}
            />
          </Paper>
        </Box>
      )}

      {props.validationResult.json.badge && props.validationResult.json.badge.issuer && (
        <Box mt={2}>
          <Typography variant="overline">
            <FormattedMessage
              id="badges-validator:issued-by-subtitle"
              defaultMessage="Issued by"
              description="Issued by subtitle"
            />
          </Typography>
          <Paper>
            <BadgesValidatorIssuer
              issuer={props.validationResult.json.badge.issuer}
            />
          </Paper>
        </Box>
      )}

      {props.validationResult.json.evidence && (
        <Box mt={2}>
          <Typography variant="overline">
            <FormattedMessage
              id="badges-validator:evidence-subtitle"
              defaultMessage="Evidence"
              description="Evidence subtitle"
            />
          </Typography>
          <Paper>
            <BadgesValidatorEvidence
              evidence={props.validationResult.json.evidence}
            />
          </Paper>
        </Box>
      )}

      {props.validationResult.json.endorsement && (
        <Box mt={2}>
          <Typography variant="overline">
            <FormattedMessage
              id="badges-validator:endorsements-subtitle"
              defaultMessage="Endorsements"
              description="Endorsements subtitle"
            />
          </Typography>
          <Paper>
            <BadgesValidatorEndorsements
              endorsement={props.validationResult.json.endorsement}
            />
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default BadgesValidatorResult;
