import {
  Box,
  Button,
  Divider,
  Icon,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
  },
});

const BadgesValidatorForm = (props) => {
  const classes = useStyle();

  const [badgeText, setBadgeText] = useState(null);
  const [file, setFile] = useState(null);

  const handleTextChange = (evt, value, val) => {
    setBadgeText(evt.target.value);
  };

  const handleValidateClick = async () => {
    props.handleFormSubmit(badgeText, file);
  };

  const handleFileDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  return (
    <>
      <Box mt={2} display="grid">
        <Typography variant="subtitle1">
          <FormattedMessage
            id="badges-validator:paste-badge-title"
            defaultMessage="Paste your Badge"
            description="Paste badge title"
          />
        </Typography>
        <TextField
          variant="outlined"
          helperText={
            <FormattedMessage
              id="add-badges.upload-text-description"
              defaultMessage="It could be a URL from a hosted badge, a JWS from a signed badge or just JSON."
              description="Message shown below upload badges by text section."
            />
          }
          placeholder="URL, JWS or JSON"
          value={badgeText}
          onChange={handleTextChange}
        ></TextField>
      </Box>
      <Box mt={2}>
        <Divider variant="middle" />
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="badges-validator:upload-badge-title"
            defaultMessage="Upload your Badge"
            description="Upload badge title"
          />
        </Typography>
        <Box display={file ? "none" : ""}>
          <Dropzone
            multiple={false}
            onDrop={(acceptedFiles) => handleFileDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <Box
                display="grid"
                p={2}
                border={1}
                borderRadius={3}
                justifyItems="center"
                className={classes.dropzone}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Icon color="action">upload</Icon>
                <Typography variant="body1" color="textSecondary">
                  <FormattedMessage
                    id="badges-validator:drag-drop-text"
                    defaultMessage="Drag 'n' drop some files here, or click to select files"
                    description="Text shown in drag and drop area"
                  />
                </Typography>
              </Box>
            )}
          </Dropzone>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              id="badges-validator:file-types-text"
              defaultMessage="It could be a PNG or SVG image."
              description="Text shown below drag and drop area"
            />
          </Typography>
        </Box>
        {file && (
          <Box>
            {file.name}
            <Button onClick={() => setFile(null)}>
              <FormattedMessage
                id="badges-validator:change-file-button"
                defaultMessage="Change"
                description="Change file button"
              />
            </Button>
          </Box>
        )}
      </Box>
      <Box mt={2} display="grid" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleValidateClick}
        >
          <FormattedMessage
            id="badges-validator:validate-button"
            defaultMessage="Validate"
            description="Validate button"
          />
        </Button>
      </Box>
    </>
  );
};

export default BadgesValidatorForm;
