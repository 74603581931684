import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";

const BadgesValidatorEvidence = (props) => {
  if (!props.evidence) {
    return "";
  }

  let evidences = Array.isArray(props.evidence) ? props.evidence : [props.evidence];

  if (!evidences.length) {
    return (
      <Typography variant="body1" color="textSecondary">
        <FormattedMessage
          id="badges-validator:empty-text"
          defaultMessage="Empty"
          description="Empty text"
        />
      </Typography>
    );
  }

  return (
    <>
      {evidences.map((evidence, pos) => (
        <Accordion key={pos}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{evidence.name ?? (
              <FormattedMessage
                id="badges-validator:evidence-subtitle"
                defaultMessage="Evidence"
              />
            )}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{typeof evidence === "string" ? evidence : (evidence.description ?? "")}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default BadgesValidatorEvidence;
