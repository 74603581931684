import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";

const BadgesValidatorEndorsements = (props) => {
  if (!props.endorsement) {
    return "";
  }

  let endorsements =
    props.endorsement instanceof Array
      ? props.endorsement
      : [props.endorsement];

  if (!endorsements.length) {
    return (
      <Typography variant="body1" color="textSecondary">
        <FormattedMessage
          id="badges-validator:empty-text"
          defaultMessage="Empty"
          description="Empty text"
        />
      </Typography>
    );
  }

  if (!endorsements[0].id) {
    return <Typography>{endorsements[0]}</Typography>;
  }

  return (
    <>
      {endorsements.map((endorsement) => (
        <Accordion key={endorsement.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <FormattedMessage
                id="badges-validator:issued-on-text"
                defaultMessage="Issued on {date}"
                description="Issued on text"
                values={{
                  date: endorsement.issuedOn,
                }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <Typography>{endorsement.issuer.name}</Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>{endorsement.claim.endorsementComment}</Typography>
              </ListItem>
              <Divider />
              <ListItem>
                <Typography>{endorsement.claim.evidence}</Typography>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default BadgesValidatorEndorsements;
