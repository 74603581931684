import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BadgesValidatorForm from "./BadgesValidatorForm";
import BadgesValidatorResult from "./BadgesValidatorResult";
import BadgesValidatorErrorsAlert from "./BadgesValidatorErrorsAlert";
import { FormattedMessage, useIntl } from "react-intl";

const validate = async (badgeText, file, axiosInstance, validationApiURL) => {
  let formData = new FormData();
  formData.append("badge", badgeText);
  formData.append("image", file);
  return axiosInstance
    .post(validationApiURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (!response || !response.data) {
        throw Error("Empty response");
      }
      return response.data;
    })
    .catch((error) => {
      return false;
    });
};

const useStyle = makeStyles((theme) => ({
  cardContent: {
    padding: "0px",
  },
  cardTitle: {
    backgroundColor: `${theme.palette.primary.light}`,
    color: `${theme.palette.primary.contrastText}`,
    padding: "10px",
  },
}));

const BadgesValidator = (props) => {
  const [validating, setValidating] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const intl = useIntl();
  const classes = useStyle();
  const axiosInstance = props.axios ?? axios.create();
  const validationApiURL = props.validationApiURL;

  useEffect(() => {
    if (props.badgeText) {
      handleFormSubmit(props.badgeText);
    }
  }, [props.badgeText]);

  const handleFormSubmit = async (badgeText, file) => {
    if (
      file !== null ||
      (typeof badgeText == "string" && badgeText.length > 0)
    ) {
      setValidating(true);
      let validationResponse = await validate(
        badgeText,
        file,
        axiosInstance,
        validationApiURL
      );
      setValidationResult(validationResponse);
      setValidating(false);
    } else {
      setValidationResult({
        errors: [
          {
            result: intl.formatMessage({
              id: "badges-validator:form-empty-error",
              defaultMessage: "You should send text or a file.",
              description: "You should send text or a file."
            }),
          },
        ],
      });
    }
  };

  const handleRetryClick = () => {
    setValidating(true);
    setValidationResult(null);
    setValidating(false);
  };

  if (validating) {
    return (
      <Paper>
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          <Box mb={2}>
            <CircularProgress />
          </Box>
          <Typography variant="body1">
            <FormattedMessage
              id="badges-validator:validating-in-progress"
              defaultMessage="Validating..."
              description="In progress validation"
            />
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Card variant="outlined">
      <CardContent className={classes.cardContent}>
        <Box
          display="grid"
          justifyContent="center"
          className={classes.cardTitle}
        >
          <Typography variant="h5">
            <FormattedMessage
              id="badges-validator:badge-validator-title"
              defaultMessage="Badge Validator"
              description="Badge Validator title"
            />
          </Typography>
        </Box>
        <Box p={2}>
          {validationResult && validationResult.errors && (
            <BadgesValidatorErrorsAlert errors={validationResult.errors} />
          )}

          {(!validationResult || !validationResult.json) && (
            <BadgesValidatorForm handleFormSubmit={handleFormSubmit} />
          )}

          {validationResult && validationResult.json && (
            <>
              <BadgesValidatorResult validationResult={validationResult} fallbackImage={props.fallbackImage} />

              <Box mt={2} display="grid" justifyContent="center">
                <Button color="primary" onClick={handleRetryClick}>
                  <FormattedMessage
                    id="badges-validator:validate-another-badge-button"
                    defaultMessage="Validate another badge"
                    description="Validate another badge button"
                  />
                </Button>
              </Box>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default BadgesValidator;
